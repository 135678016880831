import ShowAgentResultsPopupComponent from '@/components/agent/show-agent-results-popup.component';
import ShareChecklistPopupComponent from '@/components/checklist/builder/menu/share-checklist-popup.component';
import ChecklistBuilderPreviewButtonComponent from '@/components/checklist/builder/preview/checklist-builder-preview-button.component';
import {
  CLCustomButton,
  CLPrimaryCustomInput,
  CLSecondaryButton
} from '@/components/ui-controls';
import PlanAndBillingPopupComponent from '@/components/utils/plan-and-billing-popup.component';
import CLTooltip from '@/components/utils/tooltip';
import { UPDATE_CHECKLIST_MUTATION } from '@/graphql/checklist.gql';
import { updateAutoSaveAt } from '@/services/checklist/checklist.service';
import { useAutoSaveStore } from '@/stores/builder/checklist-auto-save-tracker.store';
import { useChecklistStore } from '@/stores/builder/checklist.store';
import { useOrganizationStore } from '@/stores/organization.store';
import { useMutation } from '@apollo/client';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';
import ChecklistPublishButtonComponent from '../../checklist/builder/menu/checklist-publish-button.component';
import BuilderLayoutTabs from '../builder-layout-tabs.component';
export default function DesignMenuComponent() {
  // Variables

  // States
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOrganization] = useOrganizationStore(
    state => [state.selectedOrganization],
    shallow
  );
  const [isPlanAndBillingPopupOpen, setIsPlanAndBillingPopupOpen] =
    useState<boolean>(false);

  // Store
  const [
    checklist,
    checklistTree,
    hasChecklistUpdatePermission,
    updateChecklistVersionId
  ] = useChecklistStore(
    state => [
      state.checklist,
      state.checklistTree,
      state.hasChecklistUpdatePermission,
      state.updateChecklistVersionId
    ],
    shallow
  );

  const onPublishCallback = (checklistVersionId: string) => {
    if (checklistVersionId) {
      updateChecklistVersionId(checklistVersionId);

      // Open share popup
      setIsOpen(true);
    }
  };

  return (
    <>
      <div className="border-b-[1px] border-solid border-black/10">
        <div className="w-full h-full px-4">
          <div className="flex items-center justify-between gap-4 h-14">
            {/* Left */}
            <div className="flex items-center gap-3 md:w-[25%]">
              {/* Back */}
              <Link
                title="Back"
                href={'/projects/' + (checklist?.project_id || '')}
                className="flex items-center justify-center min-w-[40px] w-10 h-10 rounded-full bg-white border-[1px] border-solid border-black/20"
              >
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.5649 0.43451C7.87732 0.746929 7.87732 1.25346 7.5649 1.56588L2.13059 7.0002L7.5649 12.4345C7.87732 12.7469 7.87732 13.2535 7.5649 13.5659C7.25248 13.8783 6.74595 13.8783 6.43353 13.5659L0.433533 7.56588C0.121114 7.25346 0.121114 6.74693 0.433533 6.43451L6.43353 0.43451C6.74595 0.12209 7.25248 0.12209 7.5649 0.43451Z"
                    fill="black"
                  />
                </svg>
              </Link>

              {/* Title */}
              <div className="w-full">
                <ChecklistTitle />
              </div>
            </div>

            {/* Center - Desktop */}
            <div className="hidden md:block h-full">
              {/* Tabs */}
              <div className="h-full">
                <BuilderLayoutTabs />
              </div>
            </div>

            {/* Right */}
            <div className="flex items-center justify-end gap-3 md:w-[25%]">
              {/* Auto save message */}
              {hasChecklistUpdatePermission && (
                <DraftOrPublishStatusMessageComponent />
              )}

              {/* AI research */}
              {/* {checklistTree?.checklist?.agent_manager_id && (
                <RevealAIProcessButtonComponent
                  agentManagerId={checklistTree?.checklist?.agent_manager_id}
                />
              )} */}

              {/* Preview */}
              {checklist && <ChecklistBuilderPreviewButtonComponent />}

              {/* Share */}
              {checklist?.checklist_version_id && (
                <>
                  <CLTooltip content="Share" position="bottom">
                    <CLCustomButton
                      className="h-full flex justify-center items-center rounded-full px-4 py-2 shadow-lg hover:bg-black/10"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <svg
                        viewBox="0 0 21 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: '20px' }}
                      >
                        <path
                          d="M20.5326 7.58997L13.1992 0.923299C13.0039 0.745308 12.7218 0.699975 12.4805 0.806632C12.2392 0.91329 12.0839 1.15261 12.0839 1.41662V4.28454C10.2065 4.28655 5.78449 4.70786 2.89596 8.3345C1.44728 10.1532 0.956578 13.3532 0.771867 14.5578L0.75784 14.6478C0.712492 14.9418 0.867144 15.2298 1.13715 15.3545C1.22715 15.3958 1.3218 15.4158 1.41645 15.4158C1.60575 15.4158 1.7911 15.3351 1.92111 15.1851C2.99313 13.9444 4.85249 12.2777 7.62103 11.3838C9.06234 10.9185 10.5604 10.7205 12.0831 10.7945V14.0825C12.0831 14.3399 12.2318 14.5745 12.4644 14.6852C12.6964 14.7945 12.9724 14.7618 13.1724 14.5985L20.5057 8.59849C20.6557 8.47585 20.745 8.29383 20.7497 8.09981C20.7551 7.90578 20.6751 7.71981 20.5318 7.5891L20.5326 7.58997Z"
                          fill="#171717"
                        ></path>
                      </svg>
                    </CLCustomButton>
                  </CLTooltip>
                </>
              )}

              {/* Share checklist popup */}
              <ShareChecklistPopupComponent
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />

              {/* Publish */}
              {checklist?.id && hasChecklistUpdatePermission && (
                <ChecklistPublishButtonComponent
                  projectId={checklist?.project_id || ''}
                  checklistId={checklist?.id}
                  checklistTitle={checklist?.title || ''}
                  checklistUpdatedAt={checklist?.updated_at || ''}
                  checklistVersionPublishedAt={
                    checklist?.checklist_version_published_at || ''
                  }
                  checklistVersionId={checklist?.checklist_version_id || ''}
                  onPublishCallback={onPublishCallback}
                />
              )}

              {/* View Plan for free Users */}
              {selectedOrganization &&
                selectedOrganization?.pricing_subscription?.pricing_plan
                  ?.pricing_plan_type?.is_freeplan && (
                  <CLSecondaryButton
                    className="whitespace-nowrap"
                    onClick={() => setIsPlanAndBillingPopupOpen(true)}
                  >
                    View Plans
                  </CLSecondaryButton>
                )}
            </div>
          </div>
        </div>
      </div>

      {/* View Plans */}
      <PlanAndBillingPopupComponent
        isOpen={isPlanAndBillingPopupOpen}
        setIsOpen={setIsPlanAndBillingPopupOpen}
      ></PlanAndBillingPopupComponent>
    </>
  );
}

function ChecklistTitle() {
  // Variables
  const saveTimeout: any = useRef(null);

  // States
  const [title, setTitle] = useState<string>('');

  // Store
  const [checklistTree, hasChecklistUpdatePermission] = useChecklistStore(
    s => [s.checklistTree, s.hasChecklistUpdatePermission],
    shallow
  );

  // GraphQL
  const [updateChecklist] = useMutation(UPDATE_CHECKLIST_MUTATION);

  useEffect(() => {
    if (checklistTree) {
      setTitle(checklistTree?.checklist?.title || '');
    } else {
      setTitle('');
    }
  }, [checklistTree, setTitle]);

  useEffect(() => {
    // This code runs after the component mounts...

    return () => {
      // This code runs before the component unmounts...

      // AutoSave, if required
      if (saveTimeout.current) {
        // Reset
        clearTimeout(saveTimeout.current);
        saveTimeout.current = null;

        saveDetails();
      }
    };
  }, []);

  function saveDetails() {
    updateChecklist({
      variables: {
        id: checklistTree?.checklist?.id,
        checklist: {
          title: checklistTree?.checklist?.title
        }
      }
    });
  }

  function startAutoSaveTimer() {
    // Clear any existing save timeout
    if (saveTimeout.current) {
      clearTimeout(saveTimeout.current); // clear the previous timer
    }

    // Update autoSavedAt time to database
    updateAutoSaveAt();

    // Set a new save timeout
    saveTimeout.current = setTimeout(() => {
      // Reset
      saveTimeout.current = null;

      saveDetails();
    }, 1000);
  }

  return (
    <>
      <CLPrimaryCustomInput
        type="text"
        style={{ padding: '8px 16px', maxWidth: '350px' }}
        className="bg-transparent text-sm w-full"
        value={title}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setTitle(event.target.value);

          // Update the change
          if (checklistTree) {
            checklistTree.checklist.title = event.target.value;
          }

          startAutoSaveTimer();
        }}
        disabled={!hasChecklistUpdatePermission}
      />
    </>
  );
}

function RevealAIProcessButtonComponent({
  agentManagerId
}: {
  agentManagerId: string;
}) {
  // States
  const [isShowAgentResultsPopupOpen, setIsShowAgentResultsPopupOpen] =
    useState<boolean>(false);

  if (!agentManagerId) {
    return <></>;
  }

  return (
    <>
      <CLSecondaryButton
        className="gap-2"
        title="Reveal AI Process"
        onClick={() => {
          setIsShowAgentResultsPopupOpen(true);
        }}
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.7252 31.0879C10.6062 31.0879 10.4867 31.06 10.3765 31.0032C10.1201 30.8717 9.96074 30.6113 9.96074 30.323V26.9847H7.70765C5.14649 26.9847 3.0625 24.9009 3.0625 22.3396V11.2379C3.0625 8.67676 5.14636 6.59277 7.70765 6.59277H24.2881C26.8493 6.59277 28.9333 8.67664 28.9333 11.2379V22.3396C28.9333 24.8996 26.8516 26.9826 24.2919 26.9847L22.6897 27.0009H18.3955L17.0827 27.0254L11.1523 30.9566C11.0233 31.0438 10.8746 31.0881 10.7252 31.0881L10.7252 31.0879ZM7.70765 8.05809C5.95418 8.05809 4.52784 9.48445 4.52784 11.2379V22.3396C4.52784 24.093 5.95421 25.5194 7.70765 25.5194H11.426V29.0171L16.6285 25.5681L18.3886 25.5355H22.6855L24.2881 25.5194C26.0415 25.5194 27.4679 24.093 27.4679 22.3396V11.2379C27.4679 9.48446 26.0415 8.05813 24.2881 8.05813L7.70765 8.05809Z"
            fill="black"
          />
          <path
            d="M11.5068 16.1859C9.86394 16.1859 8.52734 14.8493 8.52734 13.2065C8.52734 11.5637 9.86397 10.2271 11.5068 10.2271C13.1496 10.2271 14.4862 11.5637 14.4862 13.2065C14.4862 14.8493 13.1496 16.1859 11.5068 16.1859ZM11.5068 11.6921C10.672 11.6921 9.99253 12.3716 9.99253 13.2063C9.99253 14.0411 10.6717 14.7206 11.5068 14.7206C12.3419 14.7206 13.0211 14.0415 13.0211 13.2063C13.0211 12.3712 12.3416 11.6921 11.5068 11.6921Z"
            fill="black"
          />
          <path
            d="M20.5146 16.1859C18.8718 16.1859 17.5352 14.8493 17.5352 13.2065C17.5352 11.5637 18.8718 10.2271 20.5146 10.2271C22.1575 10.2271 23.4941 11.5637 23.4941 13.2065C23.4941 14.8493 22.1574 16.1859 20.5146 16.1859ZM20.5146 11.6921C19.6798 11.6921 19.0003 12.3716 19.0003 13.2063C19.0003 14.0411 19.6795 14.7206 20.5146 14.7206C21.3497 14.7206 22.0289 14.0415 22.0289 13.2063C22.0289 12.3712 21.3494 11.6921 20.5146 11.6921Z"
            fill="black"
          />
          <path
            d="M29.982 20.7624H27.5469V13.0215H29.982C31.0952 13.0215 32.0007 13.927 32.0007 15.0401V18.7438C32.0007 19.857 31.0952 20.7625 29.982 20.7625V20.7624ZM29.0121 19.2972H29.982C30.2871 19.2972 30.5355 19.0488 30.5355 18.7437V15.04C30.5355 14.7349 30.2871 14.4865 29.982 14.4865H29.0121V19.2972Z"
            fill="black"
          />
          <path
            d="M4.45379 20.7625H2.01866C0.905493 20.7618 0 19.8563 0 18.7438V15.0401C0 13.927 0.905493 13.0215 2.01866 13.0215H4.45379V20.7625ZM2.01866 14.4866C1.71354 14.4866 1.46519 14.735 1.46519 15.0401V18.7438C1.46519 19.0489 1.71354 19.2973 2.01904 19.2973H2.98864V14.4868L2.01866 14.4866Z"
            fill="black"
          />
          <path
            d="M15.9072 23.7244C14.5428 23.7244 13.227 23.2555 12.2026 22.4039C11.1762 21.5508 10.5129 20.3775 10.3349 19.1003L10.2188 18.2666H21.5958L21.4797 19.1003C21.3017 20.3776 20.6387 21.5508 19.6123 22.4039C18.5878 23.2555 17.272 23.7244 15.9071 23.7244H15.9072ZM12.0063 19.7319C12.5961 21.2183 14.1396 22.2593 15.9072 22.2593C17.6746 22.2593 19.2186 21.2183 19.8081 19.7319H12.0063Z"
            fill="black"
          />
          <path
            d="M15.9095 4.53769C14.6586 4.53769 13.6406 3.51976 13.6406 2.26884C13.6406 1.01793 14.6586 0 15.9095 0C17.1604 0 18.1783 1.01793 18.1783 2.26884C18.1783 3.51976 17.1604 4.53769 15.9095 4.53769ZM15.9095 1.46519C15.4662 1.46519 15.1058 1.82562 15.1058 2.26884C15.1058 2.71207 15.4662 3.0725 15.9095 3.0725C16.3527 3.0725 16.7131 2.71207 16.7131 2.26884C16.7131 1.82562 16.3527 1.46519 15.9095 1.46519Z"
            fill="black"
          />
          <path
            d="M15.1797 3.80469H16.6449V5.73433H15.1797V3.80469Z"
            fill="black"
          />
        </svg>
        <span className="hidden lg:block">Reveal AI Process</span>
      </CLSecondaryButton>

      <ShowAgentResultsPopupComponent
        agentId=""
        agentManagerId={agentManagerId}
        isOpen={isShowAgentResultsPopupOpen}
        setIsOpen={setIsShowAgentResultsPopupOpen}
      />
    </>
  );
}

function DraftOrPublishStatusMessageComponent() {
  // Variables

  // Store
  const { updatedAt, publishedAt } = useAutoSaveStore();

  return (
    <>
      {(!publishedAt || (updatedAt && updatedAt > publishedAt)) && (
        <span className="opacity-70 hidden lg:block">Draft saved</span>
      )}
    </>
  );
}
