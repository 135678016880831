import { getProtectedAxiosInstance } from '@/services/axios/Axios';
import { useOrganizationStore } from '@/stores/organization.store';
import { AxiosError } from 'axios';
import { useState } from 'react';
import {
  CLPrimaryCustomButton,
  CLPrimaryCustomInput,
  CLPrimaryCustomLabel
} from '../ui-controls';
import { LoadingSpinBlackCustom } from '../utils/loading.component';

export default function CouponApplyComponent() {
  // Variables

  // States
  const [haveCode, setHaveCode] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  // Store
  const { selectedOrganization } = useOrganizationStore();

  // Axios instance
  const protectedAxiosInstance = getProtectedAxiosInstance();

  function applyCoupon() {
    if (!couponCode) {
      return;
    }

    // Start processing
    setIsLoading(true);
    setErrorMsg('');
    setSuccessMsg('');

    // Apply coupon code
    const payload = {
      couponCode: couponCode,
      orgId: selectedOrganization?.id
    };
    protectedAxiosInstance.post('/redeem-coupon-code', payload).then(
      result => {
        setSuccessMsg('Coupon code applied successfully');
        setIsLoading(false);

        // Reload page to show latest plan details
        window.location.reload();
      },
      error => {
        if (error instanceof AxiosError) {
          setErrorMsg(error?.response?.data?.error?.message || error.message);
        } else {
          setErrorMsg(error);
        }

        setIsLoading(false);
      }
    );
  }

  return (
    <>
      {/* Checkbox */}
      <div className="flex items-center gap-2">
        <input
          id="have_code_id"
          type="checkbox"
          className="bg-white border-black text-black focus:ring-black/20 rounded w-4 h-4 cursor-pointer disabled:opacity-50 disabled:pointer-events-none"
          checked={haveCode || false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setHaveCode(event.target.checked);
          }}
        />
        <CLPrimaryCustomLabel
          className="flex items-center cursor-pointer pt-[2px]"
          htmlFor="have_code_id"
        >
          Do you have a coupon code?
        </CLPrimaryCustomLabel>
      </div>

      {haveCode && (
        <>
          <div className="flex gap-2 mt-2">
            <CLPrimaryCustomInput
              style={{ padding: '6px 10px' }}
              type="text"
              placeholder="Coupon Code"
              value={couponCode}
              className="w-[200px] text-sm px-[6px] py-[10px]"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCouponCode(event.target.value);
              }}
            />

            <CLPrimaryCustomButton
              className="px-4 py-2"
              onClick={() => {
                applyCoupon();
              }}
              disabled={!couponCode || isLoading}
            >
              {isLoading && (
                <LoadingSpinBlackCustom className="w-4 h-4"></LoadingSpinBlackCustom>
              )}
              Apply
            </CLPrimaryCustomButton>
          </div>

          <div>
            {/* Error message */}
            {errorMsg && <div className="mt-2 text-red-400">{errorMsg}</div>}

            {/* Success message */}
            {successMsg && (
              <div className="mt-2 text-green-600">{successMsg}</div>
            )}
          </div>
        </>
      )}
    </>
  );
}
