import { Stripe, loadStripe } from '@stripe/stripe-js';

export let stripe: Stripe;

async function _loadStripe() {
    if (!stripe) {
        stripe = await loadStripe(
            process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string
        ) as Stripe;
    }
}

_loadStripe();