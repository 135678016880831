import CommonConstants from '@/constants/common-constants';
import { GET_ORGANIZATION_SUBSCRIPTION_QUERY } from '@/graphql/organization.gql';
import { GET_DEFAULT_PRICING_QUERY } from '@/graphql/pricing.gql';
import { getProtectedAxiosInstance } from '@/services/axios/Axios';
import { useOrganizationStore } from '@/stores/organization.store';
import { Organization } from '@/types/organization.type';
import {
  PricingPlan,
  PricingPlanType,
  PricingSubscription,
  PricingVersion
} from '@/types/pricing.type';
import { useQuery } from '@apollo/client';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  CLCustomSelectOption,
  CLPrimaryCustomButton,
  CLSecondaryCustomButton
} from '../ui-controls';
import CLButton from '../ui-controls/default-ui-controls/button';
import AlertPopupComponent from '../utils/alert-popup.component';
import { LoadingSpinBlackCustom } from '../utils/loading.component';
import CouponApplyComponent from './coupon-apply.component';
import PaymentStatusPopupComponent from './payment-status-popup.component';
import PostSubscriptionPopupComponent from './post-subscription-popup.component';
import { useUserEmail } from '@nhost/nextjs';
import ChurnSurveyPopupComponent from './churn-survey-popup.component';

export default function PlanAndBillingComponent() {
  // Variables
  const planColors = ['#FFB2FF', '#B2FAB4', '#9BE7FF'];
  const router = useRouter();
  const { subscription } = router.query;
  const userEmail = useUserEmail();

  // States
  const [organization, setOrganization] = useState<Organization>();
  const [pricingVersion, setPricingVersion] = useState<PricingVersion>();
  const [currentSubscriptionProductId, setCurrentSubscriptionProductId] =
    useState<string>();
  const [currentSubscriptionQty, setCurrentSubscriptionQty] =
    useState<number>();
  const [planTypes, setPlanTypes] = useState<PricingPlanType[]>();
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<string>();
  const [selectedPeriodId, setSelectedPeriodId] = useState<string>();
  const [selectedProductId, setSelectedProductId] = useState<string>();
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1);
  const [totalOrgMembers, setTotalOrgMembers] = useState<number>(1);
  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isCustomerPortalLoading, setIsCustomerPortalLoading] =
    useState<boolean>(false);
  const [infoMessage, setInfoMessage] = useState<string>('');
  const [isPostSubscriptionPopupOpen, setIsPostSubscriptionPopupOpen] =
    useState<boolean>(false);
  const [isChurnSurveyPopupOpen, setIsChurnSurveyPopupOpen] =
    useState<boolean>(false);
  const [isSubscriptionActive, setIsSubscriptionActive] =
    useState<boolean>(false);
  const [
    isCancelOrReactivateSubscriptionLoading,
    setIsCancelOrReactivateSubscriptionLoading
  ] = useState<boolean>(false);

  // Store
  const { selectedOrganization } = useOrganizationStore();

  // GraphQL query
  const { loading, data } = useQuery<{ pricing_versions: PricingVersion[] }>(
    GET_DEFAULT_PRICING_QUERY,
    {
      variables: {}
    }
  );
  const { loading: orgLoading, data: orgData } = useQuery<{
    organization: Organization[];
  }>(GET_ORGANIZATION_SUBSCRIPTION_QUERY, {
    variables: {
      orgId: selectedOrganization?.id
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (subscription) {
      setIsPostSubscriptionPopupOpen(true);
    }
  }, [subscription]);

  useEffect(() => {
    if (data && data?.pricing_versions?.length > 0) {
      const selectedPricingVersion = data.pricing_versions[0];
      if (selectedPricingVersion?.pricing_currencies?.length > 0) {
        setSelectedCurrencyId(selectedPricingVersion?.pricing_currencies[0].id);
      }

      if (selectedPricingVersion?.pricing_periods?.length > 0) {
        const defaultPeriod = selectedPricingVersion?.pricing_periods?.find(
          period => period?.is_default_selection
        );
        if (defaultPeriod) {
          setSelectedPeriodId(defaultPeriod.id);
        }
      }

      if (selectedPricingVersion?.pricing_plan_types?.length > 0) {
        setPlanTypes(selectedPricingVersion?.pricing_plan_types);
      }

      setPricingVersion(selectedPricingVersion);
    }

    if (
      orgData &&
      orgData?.organization?.length > 0 &&
      orgData.organization[0]?.pricing_subscription
    ) {
      const organization = orgData.organization[0];
      setOrganization(organization);

      // Set current plan product_id
      if (
        organization?.pricing_subscription?.pricing_plan?.pricing_price
          ?.product_id
      ) {
        setCurrentSubscriptionProductId(
          organization?.pricing_subscription?.pricing_plan?.pricing_price
            ?.product_id
        );
      }

      // Set current subscription quantity
      if (
        organization?.pricing_subscription &&
        organization?.pricing_subscription?.qty >= 1
      ) {
        setCurrentSubscriptionQty(organization?.pricing_subscription?.qty);

        // If the current plan is not free plan, set the selected quantity
        if (
          !organization?.pricing_subscription?.pricing_plan?.pricing_plan_type
            ?.is_freeplan
        ) {
          setSelectedQuantity(organization?.pricing_subscription?.qty);
        }
      }

      // Set min quantity
      if (
        organization?.org_users_aggregate &&
        organization?.org_invites_aggregate
      ) {
        const _totalOrgMembers =
          (organization?.org_users_aggregate?.aggregate?.count || 0) +
          (organization?.org_invites_aggregate?.aggregate?.count || 0);
        setTotalOrgMembers(_totalOrgMembers);

        // If the current plan is free plan, set the selected quantity
        if (
          organization?.pricing_subscription?.pricing_plan?.pricing_plan_type
            ?.is_freeplan
        ) {
          setSelectedQuantity(_totalOrgMembers);
        }
      }

      // Check the subscription status
      if (
        organization?.pricing_subscription &&
        checkSubscriptionActiveStatus(organization?.pricing_subscription)
      ) {
        setIsSubscriptionActive(true);
      }
    }
  }, [data, orgData]);

  // Show loading
  if (loading || orgLoading) {
    return <></>;
  }

  function getPricingPlan(planType: PricingPlanType): PricingPlan | undefined {
    if (planType?.pricing_plans?.length > 0) {
      return planType?.pricing_plans?.find(
        plan =>
          plan.pricing_currency_id === selectedCurrencyId &&
          plan.pricing_period_id === selectedPeriodId
      );
    }
  }

  function getPricingPlanPrice(planType: PricingPlanType) {
    if (planType?.is_enterprise) {
      return (
        <div className="flex gap-2 items-center">
          <div className="text-2xl font-semibold">Let us talk</div>
        </div>
      );
    }

    const pricingPlan = getPricingPlan(planType);
    if (!pricingPlan) {
      return <></>;
    }

    // Free plan pricing
    if (planType?.is_freeplan) {
      return (
        <div className="flex gap-2 items-center">
          <div className="text-4xl font-semibold">
            ${pricingPlan.pricing_price.amount_per_month}
          </div>
          <div className="opacity-60">per Member/mo</div>
        </div>
      );
    }

    // Dynamic pricing based on selectedQuantity
    return (
      <div className="flex gap-2 items-center">
        <div className="text-4xl font-semibold">
          ${pricingPlan.pricing_price.amount_per_month * selectedQuantity}
        </div>
        <div className="flex flex-wrap items-center gap-[2px]">
          <span className="opacity-60">for</span>
          <CLCustomSelectOption
            className="text-sm pt-[4px] pr-[30px] pb-[4px] pl-[10px] rounded-lg"
            value={selectedQuantity}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              const selectedQuantity = parseInt(event.target.value);
              if (selectedQuantity < totalOrgMembers) {
                // Show information popup
                setInfoMessage(
                  `Planning to downgrade your plan? Please make sure to first remove any extra team members to fit the new plan's team size limit.`
                );
                setIsAlertPopupOpen(true);
              } else {
                setSelectedQuantity(selectedQuantity);
              }
            }}
          >
            {Array.from({ length: 50 }, (_, i) => i + 1).map(num => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </CLCustomSelectOption>
          <span className="opacity-60">Member(s)/mo</span>
        </div>
      </div>
    );
  }

  function getPricingTypeFeatures(
    planTypeIndex: number,
    planType: PricingPlanType
  ) {
    // const pricingPlan = getPricingPlan(planType);

    // if (!pricingPlan) {
    //   return <></>;
    // }

    return (
      <div className="flex flex-col gap-2">
        {planType?.pricing_plan_type_features?.length > 0 &&
          planType?.pricing_plan_type_features.map((feature, index) => (
            <div key={index} className="flex items-center gap-2 text-sm">
              {/* Not for title */}
              {!feature?.is_title && (
                <div
                  className="min-w-[20px] w-5 h-5 rounded-full flex items-center justify-center"
                  style={{
                    backgroundColor: planColors[planTypeIndex]
                  }}
                >
                  <svg
                    width="10"
                    height="8"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5L4 8L11 1"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}

              <div
                className={feature?.is_title ? 'font-bold mt-2' : 'opacity-60'}
              >
                {feature?.name}
              </div>
            </div>
          ))}
      </div>
    );
  }

  function checkSubscriptionActiveStatus(orgSubscription: PricingSubscription) {
    if (
      (orgSubscription?.status !== 'active' &&
        orgSubscription?.status !== 'past_due') ||
      (orgSubscription?.status === 'active' &&
        orgSubscription?.cancel_at_period_end &&
        orgSubscription?.cancel_at &&
        new Date() > new Date(parseInt(orgSubscription?.cancel_at + '000'))) ||
      (orgSubscription?.status === 'past_due' &&
        orgSubscription?.current_period_end &&
        new Date() >
          new Date(parseInt(orgSubscription?.current_period_end + '000')))
    ) {
      return false;
    }

    return true;
  }

  function getPricingPlanCTA(planTypeIndex: number, planType: PricingPlanType) {
    const pricingPlan = getPricingPlan(planType);

    if (!pricingPlan || !pricingPlan?.pricing_price.product_id) {
      return <></>;
    }

    // Current plan
    if (
      isSubscriptionActive &&
      currentSubscriptionProductId === pricingPlan?.pricing_price.product_id &&
      selectedQuantity === currentSubscriptionQty
    ) {
      return <>Your plan</>;
    }

    return (
      <>
        {/* {planType?.is_freeplan &&
          totalOrgMembers <= CommonConstants.FREE_PLAN_MEMBERS_COUNT && (
            <Link
              className="w-full"
              href={
                '/subscription?plan=' +
                pricingPlan?.id +
                '&qty=' +
                CommonConstants.FREE_PLAN_MEMBERS_COUNT
              }
            >
              <CLSecondaryCustomButton
                className="w-full py-2 text-sm"
                disabled={
                  isLoading ||
                  totalOrgMembers > CommonConstants.FREE_PLAN_MEMBERS_COUNT
                }
              >
                {isLoading &&
                  pricingPlan?.pricing_price.product_id ===
                    selectedProductId && (
                    <LoadingSpinBlackCustom className="w-4 h-4"></LoadingSpinBlackCustom>
                  )}
                Downgrade now
              </CLSecondaryCustomButton>
            </Link>
          )}
        {planType?.is_freeplan &&
          totalOrgMembers > CommonConstants.FREE_PLAN_MEMBERS_COUNT && (
            <>
              <CLSecondaryCustomButton
                className="w-full py-2 text-sm"
                disabled={
                  totalOrgMembers > CommonConstants.FREE_PLAN_MEMBERS_COUNT
                }
              >
                {isLoading &&
                  pricingPlan?.pricing_price.product_id ===
                    selectedProductId && (
                    <LoadingSpinBlackCustom className="w-4 h-4"></LoadingSpinBlackCustom>
                  )}
                Downgrade now
              </CLSecondaryCustomButton>
            </>
          )} */}

        {!planType?.is_freeplan && (
          <Link
            className="w-full"
            href={
              '/subscription?plan=' +
              pricingPlan?.id +
              '&qty=' +
              selectedQuantity
            }
          >
            <CLPrimaryCustomButton
              className="w-full py-2 text-sm"
              disabled={isLoading}
              onClick={() => {
                // changeSubscription(
                //   pricingPlan?.id,
                //   pricingPlan?.pricing_price.product_id as string,
                //   selectedQuantity
                // );
              }}
            >
              {isLoading &&
                pricingPlan?.pricing_price.product_id === selectedProductId && (
                  <LoadingSpinBlackCustom className="w-4 h-4"></LoadingSpinBlackCustom>
                )}
              Upgrade now
            </CLPrimaryCustomButton>
          </Link>
        )}
      </>
    );
  }

  function openCustomerPortal() {
    setIsCustomerPortalLoading(true);

    // Axios instance
    const protectedAxiosInstance = getProtectedAxiosInstance();

    const payload = {
      orgId: selectedOrganization?.id
    };
    protectedAxiosInstance
      .post('/stripe/get-customer-portal-link', payload)
      .then(
        result => {
          const data = result?.data?.data as { portalUrl: string };
          window.open(data.portalUrl, '_blank');
          setIsCustomerPortalLoading(false);
        },
        error => {
          setIsCustomerPortalLoading(false);
          throw error;
        }
      );
  }

  function cancelOrReactivateSubscription(
    action: string,
    subscriptionId: string
  ) {
    setIsCancelOrReactivateSubscriptionLoading(true);

    // Axios instance
    const protectedAxiosInstance = getProtectedAxiosInstance();

    const payload = {
      orgId: selectedOrganization?.id,
      action,
      subscriptionId
    };
    protectedAxiosInstance
      .post('/stripe/cancel-or-reactivate-subscription', payload)
      .then(
        result => {
          const data = result?.data?.data as {
            action: string;
            subscriptionData: {
              cancel_at?: number | undefined;
              cancel_at_period_end?: boolean;
              canceled_at?: number | undefined;
            };
          };

          // Update the organization data
          if (organization?.pricing_subscription) {
            organization.pricing_subscription.canceled_at =
              data.subscriptionData.canceled_at;
            organization.pricing_subscription.cancel_at_period_end =
              data.subscriptionData.cancel_at_period_end;
            organization.pricing_subscription.canceled_at =
              data.subscriptionData.canceled_at;

            setOrganization({ ...organization });
          }

          setIsCancelOrReactivateSubscriptionLoading(false);

          // Show churn survey
          if (action == 'cancel') {
            setIsChurnSurveyPopupOpen(true);
          }
        },
        error => {
          setIsCancelOrReactivateSubscriptionLoading(false);
          throw error;
        }
      );
  }

  return (
    <>
      {/* Current plan details */}
      {organization && (
        <div className="mt-6 max-w-md p-4 rounded-lg border-[1px]">
          {/* Status */}
          <div className="flex justify-end">
            {isSubscriptionActive ? (
              <div className="rounded-full bg-green-50 text-green-600 text-xs px-2 py-1">
                Active
              </div>
            ) : (
              <div className="rounded-full bg-red-50 text-red-600 text-xs px-2 py-1">
                Not active
              </div>
            )}
          </div>

          {/* Plan details */}
          <div className="flex justify-between mt-4">
            <div className="flex flex-col gap-2">
              <div className="text-2xl font-bold">
                {
                  organization?.pricing_subscription?.pricing_plan
                    ?.pricing_plan_type?.name
                }
              </div>
              {!organization?.pricing_subscription?.pricing_plan
                ?.pricing_plan_type?.is_freeplan &&
                !organization?.pricing_subscription?.pricing_plan
                  ?.pricing_plan_type?.is_lifetime &&
                !organization?.pricing_subscription?.pricing_plan
                  ?.pricing_plan_type?.is_enterprise && (
                  <div className="text-sm">
                    Billed{' '}
                    {
                      organization?.pricing_subscription?.pricing_plan
                        ?.pricing_period?.name
                    }
                  </div>
                )}
            </div>
            <div className="flex flex-col gap-2">
              {organization?.pricing_subscription?.qty && (
                <div className="text-2xl font-bold">
                  {organization?.pricing_subscription?.qty} members
                </div>
              )}
            </div>
          </div>

          {/* Apply coupon */}
          <div className="mt-4 flex justify-between flex-wrap gap-2">
            <div>
              <CouponApplyComponent></CouponApplyComponent>
            </div>
            <div>
              {/* Cancel or Reactive Subscription */}
              {!organization?.pricing_subscription?.pricing_plan
                ?.pricing_plan_type?.is_freeplan &&
                !organization?.pricing_subscription?.pricing_plan
                  ?.pricing_plan_type?.is_lifetime &&
                organization?.pricing_subscription?.subscription_id &&
                organization?.pricing_subscription?.status == 'active' && (
                  <div className="flex items-center gap-2">
                    {/* Cancel subscription */}
                    {!organization?.pricing_subscription
                      ?.cancel_at_period_end && (
                      <CLSecondaryCustomButton
                        className="px-2"
                        disabled={isCancelOrReactivateSubscriptionLoading}
                        onClick={() => {
                          cancelOrReactivateSubscription(
                            'cancel',
                            organization?.pricing_subscription
                              ?.subscription_id as string
                          );
                        }}
                      >
                        Cancel subscription
                      </CLSecondaryCustomButton>
                    )}

                    {/* Reactivate subscription */}
                    {organization?.pricing_subscription
                      ?.cancel_at_period_end && (
                      <CLSecondaryCustomButton
                        className="px-2"
                        disabled={isCancelOrReactivateSubscriptionLoading}
                        onClick={() => {
                          cancelOrReactivateSubscription(
                            'reactivate',
                            organization?.pricing_subscription
                              ?.subscription_id as string
                          );
                        }}
                      >
                        Reactivate subscription
                      </CLSecondaryCustomButton>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      )}

      {/* List of plans */}
      <div className="mt-6 max-w-4xl">

        {/* Why Checklist */}
        <div className="p-2 opacity-60">
          Why should I pay for Checklist? We don&apos;t run ads. We don&apos;t sell your
          data. We are 100% supported by our members.
        </div>

        {/* Title */}
        <div className="flex justify-between flex-wrap p-2">
          <div>All plans</div>

          {/* Period */}
          <div className="flex items-center gap-3">
            {pricingVersion &&
              pricingVersion?.pricing_periods?.length > 0 &&
              pricingVersion.pricing_periods.map((version, index) => (
                <div key={index} className="flex items-center gap-2">
                  <input
                    type="radio"
                    id={version?.id}
                    value={version?.id}
                    checked={selectedPeriodId === version.id}
                    onChange={event => {
                      setSelectedPeriodId(event.currentTarget.value);
                    }}
                    name="pricing_period"
                    className="bg-white border-black text-black focus:ring-black/20 rounded-full w-4 h-4"
                  />
                  <label htmlFor={version?.id}>{version?.description}</label>
                </div>
              ))}
          </div>
        </div>

        {/* Plans */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
          {planTypes &&
            planTypes.map((planType, planTypeIndex) => (
              <div
                key={planTypeIndex}
                className="rounded-lg border-[1px] overflow-hidden flex flex-col"
              >
                {/* Color */}
                <div
                  className={`w-full h-2`}
                  style={{ backgroundColor: planColors[planTypeIndex] }}
                ></div>

                {/* Details */}
                <div className="flex-grow px-4 py-8 flex flex-col gap-8">
                  {/* Title */}
                  <div className="text-xl">{planType?.name}</div>

                  {/* Price */}
                  {getPricingPlanPrice(planType)}

                  {/* Features */}
                  <div className="flex-grow">
                    {getPricingTypeFeatures(planTypeIndex, planType)}
                  </div>

                  {/* CTA */}
                  <div className="h-[38px] flex justify-center items-center">
                    {/* All plans except Enterprise plan */}
                    {!planType?.is_enterprise &&
                      getPricingPlanCTA(planTypeIndex, planType)}

                    {/* Enterprise plan */}
                    {planType?.is_enterprise && (
                      <a href="mailto: contact@checklist.gg" className="w-full">
                        <CLSecondaryCustomButton className="w-full py-2 text-sm">
                          Contact sales
                        </CLSecondaryCustomButton>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Customer portal link */}
      <div className="mt-6 text-sm opacity-60">
        To download invoices, to update card and billing information - visit{' '}
        <CLButton
          className="underline"
          disabled={isCustomerPortalLoading}
          onClick={() => {
            openCustomerPortal();
          }}
        >
          Stripe Customer Portal ↗
        </CLButton>
      </div>

      {/* Payment status */}
      {router?.query?.['payment_intent'] &&
        router?.query?.['payment_intent_client_secret'] && (
          <PaymentStatusPopupComponent
            payment_intent={router?.query?.['payment_intent'] as string}
            payment_intent_client_secret={
              router?.query?.['payment_intent_client_secret'] as string
            }
          />
        )}

      {/* Alert message */}
      <AlertPopupComponent
        title="Information"
        isOpen={isAlertPopupOpen}
        setOpen={setIsAlertPopupOpen}
      >
        {infoMessage}
      </AlertPopupComponent>

      {/* Post subscription survey popup */}
      <PostSubscriptionPopupComponent
        isOpen={isPostSubscriptionPopupOpen}
        setOpen={setIsPostSubscriptionPopupOpen}
        orgId={selectedOrganization?.id}
        email={userEmail}
      />

      {/* Churn survey popup */}
      <ChurnSurveyPopupComponent
        isOpen={isChurnSurveyPopupOpen}
        setOpen={setIsChurnSurveyPopupOpen}
        orgId={selectedOrganization?.id}
        email={userEmail}
      />
    </>
  );
}
