import PERMISSIONS_CONSTANTS from '@/constants/permission-constants';
import ROLES_CONSTANTS from '@/constants/roles-constants';
import {
  GET_ORG_USERS_AND_PROJECT_USERS_AND_PROJECT_INVITES_QUERY,
  INVITE_MEMBERS_TO_ORGANIZATION_AND_PROJECT_MUTATION
} from '@/graphql/organization.gql';
import {
  ADD_MULTIPLE_MEMBERS_TO_PROJECT_MUTATION,
  DELETE_PROJECT_INVITE_BY_ID_MUTATION,
  DELETE_PROJECT_USER_BY_ID_MUTATION,
  UPDATE_PROJECT_USER_ROLE_BY_ID_MUTATION
} from '@/graphql/projects.gql';
import { GET_PROJECT_ROLES } from '@/graphql/roles.gql';
import { getOrgInviteLink, isValidEmail } from '@/services/utils/utils.service';
import { useErrorStore } from '@/stores/error.store';
import { useOrganizationStore } from '@/stores/organization.store';
import { useProjectsStore } from '@/stores/projects.store';
import { OrgInvite, OrgUser } from '@/types/organization.type';
import { ProjectInvite, ProjectUser } from '@/types/projects.type';
import { CustomRole } from '@/types/role.type';
import { User } from '@/types/user.type';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { useUserData } from '@nhost/nextjs';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import {
  CLCustomSelectOption,
  CLPrimaryButton,
  CLPrimarySelect,
  CLSecondaryCustomButton
} from '../ui-controls';
import CLButton from '../ui-controls/default-ui-controls/button';
import ConfirmPopupComponent from '../utils/confirm-popup.component';
import { LoadingSpinBlackCustom } from '../utils/loading.component';
import { ProjectMembersDetails } from './project-members.component';
import { shallow } from 'zustand/shallow';
import Link from 'next/link';
import CLTooltip from '../utils/tooltip';

interface RemoveMemberParams {
  projectUser: ProjectUser;
  index: number;
}

interface PopupProps {
  data?: ProjectMembersDetails;
  projectId: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ProjectMembersPopupComponent({
  projectId,
  isOpen,
  setIsOpen,
  ...props
}: PopupProps) {
  // Variables

  // State
  const [projectUsers, setProjectUsers] = useState<ProjectUser[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [query, setQuery] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [invitedUsers, setInvitedUsers] = useState<ProjectInvite[]>([]);
  const [projectRoles, setProjectRoles] = useState<CustomRole[]>();
  const [isProjectMemberRoleUpdating, setIsProjectMemberRoleUpdating] =
    useState<boolean>(false);
  // Loading
  const [isInviteProcessing, setIsInviteProcessing] = useState<boolean>(false);
  const [isInviteCancelling, setIsInviteCancelling] = useState<boolean>(false);
  const [isMemberRemoving, setIsMemberRemoving] = useState<boolean>(false);

  // Store
  const { selectedOrganization, organizationPermissions } =
    useOrganizationStore();
  const userData = useUserData();
  const [projectPermissions, newProjectIds, deleteProject] = useProjectsStore(
    s => [s.projectPermissions, s.newProjectIds, s.deleteProject],
    shallow
  );
  const { open } = useErrorStore();
  const [copiedOrgInviteLink, setCopiedOrgInviteLink] = useState<{
    [key: string]: boolean;
  }>({});

  // GraphQL
  const { data, loading, error } = useQuery<ProjectMembersDetails>(
    GET_ORG_USERS_AND_PROJECT_USERS_AND_PROJECT_INVITES_QUERY,
    {
      variables: {
        orgId: selectedOrganization?.id,
        projectId: projectId
      },
      fetchPolicy: 'network-only'
    }
  );
  const { loading: projectRolesLoading, data: projectRolesData } = useQuery<{
    custom_roles: CustomRole[];
  }>(GET_PROJECT_ROLES, {
    variables: {}
  });
  const [inviteAMemberToOrganizationAndProjectMutation] = useMutation(
    INVITE_MEMBERS_TO_ORGANIZATION_AND_PROJECT_MUTATION
  );
  const [addMultipleMembersToProjectMutation] = useMutation(
    ADD_MULTIPLE_MEMBERS_TO_PROJECT_MUTATION
  );
  const [deleteProjectInvite] = useMutation(
    DELETE_PROJECT_INVITE_BY_ID_MUTATION
  );
  const [deleteProjectUser] = useMutation(DELETE_PROJECT_USER_BY_ID_MUTATION);
  const [updateProjectUserRoleById] = useMutation(
    UPDATE_PROJECT_USER_ROLE_BY_ID_MUTATION
  );

  useEffect(() => {
    // Reset fields
    if (isOpen) {
      setSelectedUsers([]);
      setQuery('');
    }

    if (!loading && data) {
      // Project users
      if (data?.project_users?.length > 0) {
        // I have created a new reference, because same custom role objects are having same reference.
        setProjectUsers(JSON.parse(JSON.stringify(data.project_users)));

        // TO DO
        // Add organization "owner and admin" users to project user's list
        // (_orgUser?.custom_role_id == ROLES_CONSTANTS?.ORGANIZATION?.OWNER ||
        // _orgUser?.custom_role_id == ROLES_CONSTANTS?.ORGANIZATION?.ADMIN)
      }

      // Remaining org users for dropdown
      if (data?.org_users?.length > 0) {
        prepareOrgUsersForDropdown(data?.org_users, data?.project_users);
      }

      // Invited users
      if (data?.project_invites?.length > 0) {
        setInvitedUsers([...data.project_invites]);
      }
    }

    // Set project roles
    if (projectRolesData?.custom_roles) {
      setProjectRoles(projectRolesData?.custom_roles);
    }
  }, [isOpen, loading, data, projectRolesData]);

  function prepareOrgUsersForDropdown(
    _orgUsers: OrgUser[],
    _projectUsers: ProjectUser[]
  ) {
    if (_orgUsers?.length > 0) {
      // Filter existing members
      const remainingUsers = _orgUsers.filter(_orgUser => {
        return (
          _projectUsers.findIndex(
            _projectUser => _projectUser?.user?.id == _orgUser?.user?.id
          ) == -1
        );
      });

      if (remainingUsers?.length > 0) {
        setUsers([
          ...remainingUsers.map(org_user =>
            org_user.user ? org_user.user : {}
          )
        ]);
      }
    }
  }

  function getInsertInvites() {
    const orgInvites: OrgInvite[] = [];
    const projectInvites: ProjectInvite[] = [];

    const newUsers = selectedUsers?.filter(
      selectedUser => selectedUser?.id == selectedUser?.displayName
    );

    if (newUsers?.length > 0) {
      newUsers.forEach(_user => {
        orgInvites.push({
          org_id: selectedOrganization?.id as string,
          email: _user?.id,
          custom_role_id: ROLES_CONSTANTS.ORGANIZATION.MEMBER
        });

        projectInvites.push({
          org_id: selectedOrganization?.id as string,
          project_id: projectId,
          email: _user?.id,
          custom_role_id: ROLES_CONSTANTS.PROJECT.MEMBER
        });
      });
    }

    return { orgInvites, projectInvites };
  }

  function getInsertProjectMembers() {
    const projectMembers: ProjectUser[] = [];

    const newUsers = selectedUsers?.filter(
      selectedUser => selectedUser?.id != selectedUser?.displayName
    );

    newUsers.forEach(_user => {
      projectMembers.push({
        project_id: projectId,
        user_id: _user?.id,
        custom_role_id: ROLES_CONSTANTS.PROJECT.MEMBER
      });
    });

    return projectMembers;
  }

  async function sendInvite() {
    try {
      if (selectedUsers && selectedUsers?.length > 0) {
        setIsInviteProcessing(true);

        // Get all new users - org_invites, project_invites
        const { orgInvites, projectInvites } = getInsertInvites();
        if (orgInvites?.length > 0 || projectInvites?.length > 0) {
          const { data: result, extensions: _projectExtentions } =
            await inviteAMemberToOrganizationAndProjectMutation({
              variables: {
                orgInvites: orgInvites,
                projectInvites: projectInvites
              }
            });

          // Update UI
          if (
            result?.['InviteMembersToOrganizationAndProject']?.[
              'insert_org_invites'
            ] ||
            result?.['InviteMembersToOrganizationAndProject']?.[
              'insert_project_invites'
            ]
          ) {
            // Inserted successfully
            if (
              result?.['InviteMembersToOrganizationAndProject']?.[
                'insert_project_invites'
              ]?.returning?.length > 0
            ) {
              setInvitedUsers([
                ...invitedUsers,
                ...result.InviteMembersToOrganizationAndProject
                  .insert_project_invites.returning
              ]);
            }
          }
        }

        // Add existing users to this project  - project_users
        const projectMembers = getInsertProjectMembers();
        if (projectMembers?.length > 0) {
          const { data: result, extensions: _projectExtentions } =
            await addMultipleMembersToProjectMutation({
              variables: {
                projectUsers: projectMembers
              }
            });

          // Update UI
          if (result?.['insert_project_users']?.returning?.length > 0) {
            // Inserted successfully
            const newProjectUsers = [
              ...projectUsers,
              ...result.insert_project_users.returning
            ];

            setProjectUsers(newProjectUsers);

            // Remove inserted project-users from dropdown
            setUsers(
              users?.filter(
                _user =>
                  newProjectUsers.findIndex(
                    _newUser => _newUser?.user?.id == _user?.id
                  ) == -1
              )
            );
          }
        }

        // Reset
        setSelectedUsers([]);
        setIsInviteProcessing(false);
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        open(error.message);
        setIsInviteProcessing(false);
      }

      throw error;
    }
  }

  function RemoveMemberCTA({ projectUser, index }: RemoveMemberParams) {
    // Variables

    // States
    const [removeMemberParams, setRemoveMemberParams] =
      useState<RemoveMemberParams>();
    const [isRemoveMemberConfirmPopupOpen, setIsRemoveMemberConfirmPopupOpen] =
      useState<boolean>(false);

    return (
      <>
        <CLSecondaryCustomButton
          className="text-xs px-2 py-1 opacity-70 hover:opacity-100"
          disabled={isMemberRemoving}
          onClick={() => {
            setRemoveMemberParams({
              projectUser: projectUser,
              index: index
            });
            setIsRemoveMemberConfirmPopupOpen(true);
          }}
        >
          {userData?.id == projectUser?.user?.id ? 'Leave' : 'Remove'}
        </CLSecondaryCustomButton>

        {removeMemberParams && (
          <ConfirmPopupComponent
            title={
              userData?.id == projectUser?.user?.id
                ? 'Leave project?'
                : 'Remove member?'
            }
            description={`Are you sure you want to ${
              userData?.id == projectUser?.user?.id
                ? 'leave'
                : 'remove this member'
            } from the project? This action cannot be undone.`}
            buttonText={
              userData?.id == projectUser?.user?.id ? 'Leave' : 'Remove'
            }
            buttonClick={removeMember}
            buttonParams={removeMemberParams}
            isOpen={isRemoveMemberConfirmPopupOpen}
            setOpen={setIsRemoveMemberConfirmPopupOpen}
          />
        )}
      </>
    );
  }

  async function removeMember(params: RemoveMemberParams | undefined) {
    if (!params) {
      return;
    }

    // Get values from params object
    const { index, projectUser } = params;

    setIsMemberRemoving(true);
    await deleteProjectUser({
      variables: {
        id: projectUser?.id
      }
    });

    projectUsers.splice(index, 1);
    setProjectUsers([...projectUsers]);

    if (projectUser?.user) {
      users.push(projectUser.user);
      setUsers([...users]);
    }

    // Remove Project and close the Popup
    if (userData?.id == projectUser?.user?.id) {
      deleteProject(projectId);
      setIsOpen(false);
    }

    setIsMemberRemoving(false);
  }

  async function cancelInvite(cancelInvitedUser: ProjectInvite, index: number) {
    setIsInviteCancelling(true);
    await deleteProjectInvite({
      variables: {
        id: cancelInvitedUser?.id
      }
    });

    invitedUsers.splice(index, 1);
    setInvitedUsers([...invitedUsers]);

    setIsInviteCancelling(false);
  }

  async function changeProjectMemberRole(
    projectUserId: string,
    roleId: string,
    projectUser: ProjectUser
  ) {
    setIsProjectMemberRoleUpdating(true);

    await updateProjectUserRoleById({
      variables: {
        id: projectUserId,
        set: {
          custom_role_id: roleId
        }
      }
    });

    // Refresh the UI
    if (projectUser?.custom_role?.id && projectUsers) {
      projectUser.custom_role.id = roleId;
      setProjectUsers([...projectUsers]);
    }

    setIsProjectMemberRoleUpdating(false);
  }

  async function copyOrgInviteLink(orgId: string, email: string) {
    try {
      const orgInviteLink = getOrgInviteLink(orgId, email as string);

      await navigator.clipboard.writeText(orgInviteLink);

      setCopiedOrgInviteLink(prevState => ({ ...prevState, [email]: true }));

      setTimeout(() => {
        setCopiedOrgInviteLink(prevState => ({ ...prevState, [email]: false }));
      }, 500);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }

  const filteredOptions = users.filter(option => {
    // If it is already added, don't consider for displayName matching
    const alreadySelected = selectedUsers.find(
      _selectedUser => _selectedUser?.id == option?.id
    );
    return (
      !alreadySelected &&
      option?.displayName?.toLowerCase().includes(query.trim().toLowerCase())
    );
  });

  const combinedOptions =
    query && filteredOptions.length == 0
      ? [
          ...users,
          {
            id: query,
            displayName: query
          }
        ]
      : users;

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-6 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-lg bg-white p-4 text-left align-middle shadow-xl transition-all">
                  {/* Header */}
                  <div className="flex justify-between items-center">
                    <div>
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Project Members
                      </Dialog.Title>
                    </div>

                    <div>
                      <CLButton
                        className="p-2"
                        tabIndex={-1}
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L8 6.58579L14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893C16.0976 0.683417 16.0976 1.31658 15.7071 1.70711L9.41421 8L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8 9.41421L1.70711 15.7071C1.31658 16.0976 0.683417 16.0976 0.292893 15.7071C-0.0976311 15.3166 -0.0976311 14.6834 0.292893 14.2929L6.58579 8L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                            fill="black"
                            fillOpacity="0.5"
                          />
                        </svg>
                      </CLButton>
                    </div>
                  </div>

                  {/* Body */}
                  <div className="flex flex-col gap-4 divide-y divide-gray-100">
                    {loading && (
                      <>
                        <div className="mt-4 w-full h-[50px] bg-[#EDE4E0] rounded-lg"></div>
                        <div className="py-2">
                          {/* Title */}
                          <span>Members</span>

                          <div className="mt-4 flex flex-col gap-4">
                            <div className="w-full h-[44px] bg-[#EDE4E0] rounded-lg"></div>
                            <div className="w-full h-[44px] bg-[#EDE4E0] rounded-lg"></div>
                          </div>
                        </div>
                      </>
                    )}

                    {!loading && (
                      <>
                        {/* Invite a member */}
                        {projectId &&
                          (newProjectIds[projectId] ||
                            projectPermissions?.[projectId]?.[
                              PERMISSIONS_CONSTANTS.MANAGE_PROJECT_MEMBERS
                            ]) && (
                            <>
                              <div className="mt-4 flex items-center gap-2">
                                <div className="flex-1 text-sm">
                                  <CLPrimarySelect
                                    placeholder="Name or Email"
                                    isMulti={true}
                                    value={selectedUsers}
                                    options={combinedOptions}
                                    getOptionLabel={option =>
                                      option?.displayName || ''
                                    }
                                    getOptionValue={option =>
                                      option.id as string
                                    }
                                    inputValue={query}
                                    onInputChange={value => {
                                      setQuery(value);
                                    }}
                                    onChange={(selectedMembers, action) => {
                                      if (Array.isArray(selectedMembers)) {
                                        setSelectedUsers([...selectedMembers]);
                                      }
                                    }}
                                    isOptionDisabled={option => {
                                      if (
                                        option.id &&
                                        option?.id == option?.displayName &&
                                        (!isValidEmail(option?.id) ||
                                          !organizationPermissions?.[
                                            PERMISSIONS_CONSTANTS
                                              .MANAGE_ORGANIZATION_MEMBERS
                                          ])
                                      ) {
                                        return true;
                                      }

                                      return false;
                                    }}
                                  />
                                </div>
                                <div>
                                  {/* Invite */}
                                  <CLPrimaryButton
                                    disabled={
                                      selectedUsers?.length == 0 ||
                                      isInviteProcessing
                                    }
                                    onClick={sendInvite}
                                  >
                                    {isInviteProcessing && (
                                      <LoadingSpinBlackCustom className="w-4 h-4"></LoadingSpinBlackCustom>
                                    )}
                                    Invite
                                  </CLPrimaryButton>

                                </div>
                              </div>

                              {/* Note */}
                              <div className="text-xs opacity-50">
                                Note: To add someone, select from existing
                                organization members or invite new ones by
                                entering a valid email address.
                              </div>
                            </>
                          )}

                        {/* List of project members*/}
                        <div className="py-2">
                          {/* Title */}
                          <span>Teammates</span>

                          <div className="flex flex-col gap-4 mt-4">
                            {projectUsers &&
                              projectUsers?.map((projectUser, index) => (
                                // Each member
                                <div
                                  className="flex justify-between flex-wrap md:flex-nowrap gap-2"
                                  key={index}
                                >
                                  {/* Left */}
                                  <div className="flex justify-center items-center gap-2">
                                    {/* Icon */}
                                    <img
                                      className="w-10 h-10 rounded-full mx-auto bg-slate-300"
                                      src={projectUser?.user?.avatarUrl}
                                      alt=""
                                      width="384"
                                      height="512"
                                    />

                                    {/* Name and email */}
                                    <div>
                                      <div
                                        className="text-md line-clamp-1"
                                        title={projectUser?.user?.displayName}
                                      >
                                        {projectUser?.user?.displayName}
                                      </div>
                                      <div
                                        className="text-sm cl_secondary_color opacity-50 line-clamp-1"
                                        title={projectUser?.user?.email}
                                      >
                                        {projectUser?.user?.email}
                                      </div>
                                    </div>
                                  </div>

                                  {/* Right */}
                                  <div className="flex items-center gap-2">
                                    {projectUsers &&
                                      projectUsers?.length > 1 && (
                                        <>
                                          {/* Action */}
                                          {(userData?.id ==
                                            projectUser?.user?.id ||
                                            (projectId &&
                                              (newProjectIds[projectId] ||
                                                projectPermissions?.[
                                                  projectId
                                                ]?.[
                                                  PERMISSIONS_CONSTANTS
                                                    .MANAGE_PROJECT_MEMBERS
                                                ]))) && (
                                            <RemoveMemberCTA
                                              projectUser={projectUser}
                                              index={index}
                                            />
                                          )}
                                        </>
                                      )}

                                    {/* Role */}
                                    <div className="text-sm">
                                      {projectId &&
                                      (newProjectIds[projectId] ||
                                        projectPermissions?.[projectId]?.[
                                          PERMISSIONS_CONSTANTS
                                            .MANAGE_PROJECT_MEMBERS
                                        ]) ? (
                                        <CLCustomSelectOption
                                          className="text-xs pt-[4px] pr-[30px] pb-[4px] pl-[10px] rounded-full"
                                          value={projectUser?.custom_role?.id}
                                          onChange={(
                                            event: React.ChangeEvent<HTMLSelectElement>
                                          ) => {
                                            if (
                                              projectUser?.id &&
                                              projectUser?.custom_role?.id
                                            ) {
                                              changeProjectMemberRole(
                                                projectUser?.id,
                                                event.target.value,
                                                projectUser
                                              );
                                            }
                                          }}
                                          disabled={
                                            userData?.id ==
                                              projectUser?.user?.id ||
                                            isProjectMemberRoleUpdating
                                          }
                                        >
                                          {projectRoles &&
                                            projectRoles.length > 0 &&
                                            projectRoles.map((role, index) => (
                                              <option
                                                key={index}
                                                value={role.id}
                                              >
                                                {role.title}
                                              </option>
                                            ))}
                                        </CLCustomSelectOption>
                                      ) : (
                                        <>{projectUser?.custom_role?.title}</>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>

                        {/* List of project invited members */}
                        {invitedUsers && invitedUsers?.length > 0 && (
                          <div className="py-2">
                            {/* Title */}
                            <span>Invites</span>

                            <div className="flex flex-col gap-4 mt-4">
                              {invitedUsers?.map((invitedUser, index) => (
                                // Each member
                                <div
                                  className="flex flex-wrap gap-2 justify-between"
                                  key={index}
                                >
                                  {/* Left */}
                                  <div className="flex justify-center items-center gap-4">
                                    {/* Email */}
                                    <div>
                                      <div className="text-sm">
                                        {invitedUser?.email}
                                      </div>
                                    </div>
                                  </div>

                                  {/* Right */}
                                  <div className="flex items-center gap-2">
                                    {/* Copy invite link */}
                                    <CLSecondaryCustomButton
                                      className="text-xs px-2 py-1 opacity-70 hover:opacity-100"
                                      disabled={
                                        isMemberRemoving ||
                                        copiedOrgInviteLink[
                                          invitedUser?.email as string
                                        ]
                                      }
                                      onClick={() => {
                                        copyOrgInviteLink(
                                          selectedOrganization?.id as string,
                                          invitedUser?.email as string
                                        );
                                      }}
                                    >
                                      {invitedUser?.email &&
                                      copiedOrgInviteLink[
                                        invitedUser?.email as string
                                      ]
                                        ? 'Copied'
                                        : 'Copy invite link'}
                                    </CLSecondaryCustomButton>

                                    {/* Action */}
                                    <CLSecondaryCustomButton
                                      className="text-xs px-2 py-1 opacity-70 hover:opacity-100"
                                      disabled={isInviteCancelling}
                                      onClick={() => {
                                        cancelInvite(invitedUser, index);
                                      }}
                                    >
                                      Cancel
                                    </CLSecondaryCustomButton>

                                    {/* Role */}
                                    <div className="text-sm">
                                      {invitedUser?.custom_role?.title}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
