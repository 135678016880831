import CLButton from '@/components/ui-controls/default-ui-controls/button';
import { Dialog, Transition } from '@headlessui/react';
import { Dispatch, Fragment, SetStateAction } from 'react';
import ChecklistBuilderPreviewComponent from './checklist-builder-preview.component';
import ChecklistPrintPdfComponent from './checklist-print-pdf.component';
import CLTooltip from '@/components/utils/tooltip';

interface PopupProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ChecklistBuilderPreviewPopupComponent({
  isOpen,
  setIsOpen
}: PopupProps) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-6 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform rounded-lg bg-white p-4 text-left align-middle shadow-xl transition-all">
                  {/* Header */}
                  <div className="mb-4 flex items-center justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Preview
                    </Dialog.Title>

                    <div className="flex justify-center items-center gap-3">
                      {/* Print pdf */}
                      <CLTooltip content="Print">
                        <ChecklistPrintPdfComponent />
                      </CLTooltip>

                      {/* Download pdf */}
                      <CLTooltip content="Download PDF">
                        <ChecklistPrintPdfComponent isDownload={true} />
                      </CLTooltip>

                      {/* Close icon */}
                      <CLButton
                        className="p-2"
                        tabIndex={-1}
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L8 6.58579L14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893C16.0976 0.683417 16.0976 1.31658 15.7071 1.70711L9.41421 8L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8 9.41421L1.70711 15.7071C1.31658 16.0976 0.683417 16.0976 0.292893 15.7071C-0.0976311 15.3166 -0.0976311 14.6834 0.292893 14.2929L6.58579 8L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                            fill="black"
                            fillOpacity="0.5"
                          />
                        </svg>
                      </CLButton>
                    </div>
                  </div>

                  {/* Body */}
                  <div>
                    {/* Preview */}
                    <div className="flex-[0_0_100%] md:flex-[0_0_60%] 2xl:flex-[0_0_60%] overflow-hidden h-full">
                      <ChecklistBuilderPreviewComponent />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
