import { CLCustomButton } from '@/components/ui-controls';
import { useChecklistStore } from '@/stores/builder/checklist.store';
import { useChecklistStatusIndicatorsStore } from '@/stores/builder/settings/checklist-status-indicators.store';
import { Checklist } from '@/types/checklist.type';
import { ChecklistStatusIndicator } from '@/types/status-indicators.type';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { shallow } from 'zustand/shallow';
import { ChecklistTree } from '../../checklist-by-id.component';

export default function ChecklistPrintPdfComponent({
  isDownload,
  isPrint
}: {
  isDownload?: boolean;
  isPrint?: boolean;
}) {
  // Variables

  // Store
  const [checklist, checklistTree] = useChecklistStore(
    s => [s.checklist, s.checklistTree],
    shallow
  );
  const [checklistStatusIndicators] = useChecklistStatusIndicatorsStore(
    s => [s.checklistStatusIndicators],
    shallow
  );

  const print = () => {
    if (checklist) {
      const filename =
        (checklist['title']
          ? capitalizeFirstLetter(checklist['title'])
          : 'Untitled') + '.pdf';

      const doc = new jsPDF('p', 'in', 'a4');
      doc.setDrawColor('black');

      // Get printable output
      const checklistHeaderAndBody =
        prepareChecklistDataForPdfDownload(checklist);

      // Dynamic columns - 7.25 total cell width
      const dynamicColumns: any = {};
      if (checklistStatusIndicators && checklistStatusIndicators.length > 0) {
        // Title column
        const statusIndicatorSpacingWidth =
          checklistStatusIndicators.length * 1.05;
        dynamicColumns[0] = { cellWidth: 7.25 - statusIndicatorSpacingWidth };

        // Status indicator columns
        checklistStatusIndicators.forEach((indicator, index) => {
          dynamicColumns[index + 1] = {
            cellWidth: 1.05
          };
        });
      } else {
        // Title
        dynamicColumns[0] = { cellWidth: 6.2 };

        // Status indicator column
        dynamicColumns[1] = { cellWidth: 1.05 };
      }

      // Table format
      autoTable(doc, {
        columnStyles: dynamicColumns,
        head: checklistHeaderAndBody.headerRow,
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 0.15,
          font: 'Helvetica',
          fontStyle: 'normal',
          fontSize: 11
        },
        didParseCell: (data: any) => {
          // Exclude title column
          if (data.section === 'head' && data.column.index != 0) {
            // Set text alignment to center for the specified columns
            data.cell.styles.halign = 'center';
            data.cell.styles.cellPadding = 0.15;
          }
        },
        body: checklistHeaderAndBody.taskRows,
        theme: 'striped',
        pageBreak: 'auto',
        rowPageBreak: 'avoid',
        didDrawCell: (data: any) => {
          // Exclude title column
          if (data.section === 'body' && data.column.index != 0) {
            // Calculate the height of the text in the first column (index 0)
            const firstColumnText = data.row.raw[0];
            const cellHeight = doc.getTextDimensions(firstColumnText, {
              fontSize: 11 // Adjust the font size as needed
            }).h;

            // Calculate the vertical position to center the box within the cell
            const extraPadding = 0.15;
            const verticalPosition =
              data.cell.y + (data.cell.height - cellHeight + extraPadding) / 2;

            // Draw the rectangular box at the center
            doc.setDrawColor('black');
            doc.rect(data.cell.x + 0.4, verticalPosition - 0.125, 0.25, 0.25);
          }
        },
        startY: 1.25,
        bodyStyles: {
          font: 'Helvetica',
          fontStyle: 'normal',
          fontSize: 11,
          cellPadding: 0.15
        },
        didDrawPage: data => {
          const doc = data.doc;
          const totalPages = doc.internal.getNumberOfPages();
          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;
          const watermarkText = 'checklist.gg'; // Customize the watermark text

          const textWidth =
            (doc.getStringUnitWidth(watermarkText) *
              doc.internal.getFontSize()) /
            doc.internal.scaleFactor;

          for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);

            doc.setFontSize(10);
            doc.setTextColor(169, 169, 169); // Set gray text color (RGB: 169, 169, 169)

            // Calculate the position for the watermark at the bottom right corner
            const x = pageWidth - textWidth - 0.005; // Adjust the 10 for desired right margin
            const y = pageHeight - 0.5; // Adjust the 10 for desired bottom margin

            doc.text(watermarkText, x, y);
          }
        }
      });

      if (isDownload) {
        // Save pdf
        doc.save(filename);
      } else {
        // Print pdf
        doc.output('dataurlnewwindow', {
          filename: filename
        });
      }
    }
  };

  const getAllTasksRow = (
    checklistTree: ChecklistTree,
    parentDisplayNumber: string,
    taskRows: string[][]
  ) => {
    if (checklistTree?.children?.length > 0) {
      checklistTree.children.forEach((child, childIndex) => {
        const myDisplayNumber =
          (parentDisplayNumber ? '  ' : '') +
          (parentDisplayNumber ? parentDisplayNumber : '') +
          (childIndex + 1) +
          '.';
        const taskTitle = myDisplayNumber + ' ' + child?.checklist?.title || '';
        const individualTask = [taskTitle];
        taskRows.push(individualTask);

        getAllTasksRow(child, myDisplayNumber, taskRows);
      });
    }
  };

  const prepareChecklistDataForPdfDownload = (checklist: Checklist) => {
    if (!checklist || !checklistTree) {
      return { headerRow: [], taskRows: [] };
    }

    // Get Headers
    const headerRow: string[] = [checklist?.title || 'Untitled'];
    if (checklistStatusIndicators && checklistStatusIndicators.length > 0) {
      checklistStatusIndicators.forEach((status: ChecklistStatusIndicator) => {
        if (status?.name) {
          headerRow.push(status.name);
        }
      });
    } else {
      headerRow.push('Completed');
    }

    // Get Tasks
    const taskRows: string[][] = [];
    getAllTasksRow(checklistTree, '', taskRows);

    return { headerRow: [headerRow], taskRows: taskRows };
  };

  const capitalizeFirstLetter = (text: string) => {
    if (text && text.length > 0) {
      const words = text.split(' ');

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      return words.join(' ');
    }

    return text;
  };

  return (
    <>
      {isDownload ? (
        <>
          {/* Download button */}
          <CLCustomButton
            className="flex justify-center items-center rounded-full w-[34px] h-[34px] hover:bg-slate-100"
            onClick={() => print()}
            title="Download PDF"
          >
            <svg
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ height: '18px' }}
            >
              <path
                d="M2.42 16.5116C1.88667 16.5116 1.43333 16.325 1.06 15.9516C0.686667 15.5783 0.5 15.125 0.5 14.5916V11.7116H2.1V14.5916C2.1 14.6627 2.13556 14.7338 2.20667 14.805C2.27778 14.8761 2.34889 14.9116 2.42 14.9116H14.58C14.6511 14.9116 14.7222 14.8761 14.7933 14.805C14.8644 14.7338 14.9 14.6627 14.9 14.5916V11.7116H16.5V14.5916C16.5 15.125 16.3133 15.5783 15.94 15.9516C15.5667 16.325 15.1133 16.5116 14.58 16.5116H2.42ZM8.5 12.3783L3.94 7.81828L5.06 6.64495L7.7 9.28495V0.324951H9.3V9.28495L11.94 6.64495L13.06 7.81828L8.5 12.3783Z"
                fill="black"
              ></path>
            </svg>
          </CLCustomButton>
        </>
      ) : (
        <>
          {/* Print button */}
          <CLCustomButton
            className="flex justify-center items-center rounded-full w-[34px] h-[34px] hover:bg-slate-100"
            onClick={() => print()}
            title="Print"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ height: '18px' }}
            >
              <path
                d="M20.25 6.75H18.75V3.75C18.75 3.15328 18.513 2.58094 18.091 2.15898C17.6691 1.73702 17.0967 1.5 16.5 1.5H7.5C6.90328 1.5 6.33094 1.73704 5.90898 2.15898C5.48702 2.58094 5.25 3.15328 5.25 3.75V6.75H3.75C3.15328 6.75 2.58094 6.98704 2.15898 7.40898C1.73702 7.83094 1.5 8.40328 1.5 9V15C1.5 15.5967 1.73704 16.1691 2.15898 16.591C2.58094 17.013 3.15328 17.25 3.75 17.25H5.25V21.75C5.25 21.9489 5.32898 22.1397 5.46968 22.2803C5.6103 22.421 5.80108 22.5 6 22.5H18C18.1989 22.5 18.3897 22.421 18.5303 22.2803C18.671 22.1397 18.75 21.9489 18.75 21.75V17.25H20.25C20.8467 17.25 21.4191 17.013 21.841 16.591C22.263 16.1691 22.5 15.5967 22.5 15V9C22.5 8.40328 22.263 7.83094 21.841 7.40898C21.4191 6.98702 20.8467 6.75 20.25 6.75ZM6.75 3.75C6.75 3.55109 6.82898 3.36032 6.96968 3.21968C7.1103 3.07898 7.30108 3 7.5 3H16.5C16.6989 3 16.8897 3.07898 17.0303 3.21968C17.171 3.3603 17.25 3.55108 17.25 3.75V6.75H6.75V3.75ZM4.5 10.5C4.5 10.3011 4.57898 10.1103 4.71968 9.96968C4.8603 9.82898 5.05108 9.75 5.25 9.75H8.25C8.51796 9.75 8.76554 9.89297 8.89954 10.125C9.03352 10.357 9.03352 10.643 8.89954 10.875C8.76556 11.107 8.51798 11.25 8.25 11.25H5.25C5.05109 11.25 4.86032 11.171 4.71968 11.0303C4.57898 10.8897 4.5 10.6989 4.5 10.5ZM17.25 21H6.75V14.25H17.25V21ZM15 17.25H9C8.73204 17.25 8.48446 17.107 8.35046 16.875C8.21648 16.643 8.21648 16.357 8.35046 16.125C8.48444 15.893 8.73202 15.75 9 15.75H15C15.268 15.75 15.5155 15.893 15.6495 16.125C15.7835 16.357 15.7835 16.643 15.6495 16.875C15.5156 17.107 15.268 17.25 15 17.25ZM13.5 19.5H9C8.73204 19.5 8.48446 19.357 8.35046 19.125C8.21648 18.893 8.21648 18.607 8.35046 18.375C8.48444 18.143 8.73202 18 9 18H13.5C13.768 18 14.0155 18.143 14.1495 18.375C14.2835 18.607 14.2835 18.893 14.1495 19.125C14.0156 19.357 13.768 19.5 13.5 19.5Z"
                fill="black"
              />
            </svg>
          </CLCustomButton>
        </>
      )}
    </>
  );
}
