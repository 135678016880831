import { GET_ORGANIZATION_PROJECTS_QUERY } from '@/graphql/organization.gql';
import { useOrganizationStore } from '@/stores/organization.store';
import { useProjectsStore } from '@/stores/projects.store';
import { Projects } from '@/types/projects.type';
import { useQuery } from '@apollo/client';
import { useUserId } from '@nhost/nextjs';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

export function FetchProjectComponent() {
  // Variables
  const userId = useUserId();
  const router = useRouter();

  // Stores
  const [selectedOrganization] = useOrganizationStore(
    s => [s.selectedOrganization],
    shallow
  );
  const [isLoading, setIsLoading, orgId, setProjects] = useProjectsStore(
    s => [s.isLoading, s.setIsLoading, s.orgId, s.setProjects],
    shallow
  );

  // GraphQL
  const { data, loading, error } = useQuery<Projects>(
    GET_ORGANIZATION_PROJECTS_QUERY,
    {
      variables: { id: selectedOrganization?.id, userId },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    // Show loading
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (
      selectedOrganization?.id &&
      data?.projects &&
      (isLoading || orgId != selectedOrganization?.id)
    ) {
      setProjects(selectedOrganization?.id, data.projects);

      // Is it a project page?
      if (document.location.pathname.startsWith('/projects/')) {
        // Get the project id from the URL
        const projectId = document.location.pathname.split('/')[2];
        if (projectId) {
          // Check if the project id exists in the projects list
          const currentProjectExists = data.projects.find(
            p => p.id === projectId
          );
          if (!currentProjectExists) {
            // If the projects are empty, redirect to the /projects
            if (data.projects.length === 0) {
              router.push('/projects');
            } else {
              // Redirect to the first project
              router.push(`/projects/${data.projects[0].id}`);
            }
          }
        }
      }
    }
  }, [selectedOrganization, isLoading, data, setProjects, orgId]);

  return <></>;
}
