import { stripe } from '@/services/stripe/stripe.service';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import AlertPopupComponent from '../utils/alert-popup.component';
import { LoadingSpinBlackCustom } from '../utils/loading.component';

interface Props {
  payment_intent: string;
  payment_intent_client_secret: string;
}

export default function PaymentStatusPopupComponent({
  payment_intent,
  payment_intent_client_secret,
  ...props
}: Props) {
  // Variables

  // State
  const [isPaymentStatusPopupOpen, setIsPaymentStatusPopupOpen] =
    useState<boolean>(true);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (payment_intent_client_secret) {
      verifyClientSecret(payment_intent_client_secret);
    }
  }, [payment_intent_client_secret]);

  function verifyClientSecret(payment_intent_client_secret: string) {
    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(payment_intent_client_secret)
      .then(
        ({ paymentIntent, ...paymentIntentResult }: PaymentIntentResult) => {
          switch (paymentIntent?.status) {
            case 'succeeded':
              setMessage('Success! Payment method is setup.');
              break;
            case 'processing':
              setMessage('Payment method setup processing.');
              break;
            case 'requires_payment_method':
              setMessage(
                'Payment method setup is failed. Please try adding another payment method.'
              );
              // Redirect your user back to your payment page to attempt collecting
              // payment again
              break;
            default:
              setMessage('Something went wrong.');
              break;
          }
        }
      );
  }

  return (
    <>
      <AlertPopupComponent
        title="Information"
        isOpen={isPaymentStatusPopupOpen}
        setOpen={setIsPaymentStatusPopupOpen}
      >
        {message ? (
          message
        ) : (
          <div className="flex items-center">
            <LoadingSpinBlackCustom className="w-4 h-4"></LoadingSpinBlackCustom>
            <div>Loading</div>
          </div>
        )}
      </AlertPopupComponent>
    </>
  );
}
