import { Dialog, Transition } from '@headlessui/react';
import { Dispatch, Fragment, SetStateAction, useEffect } from 'react';

interface PopupProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  orgId: string | undefined;
  email: string | undefined;
}

export default function PostSubscriptionPopupComponent({
  isOpen,
  setOpen,
  orgId,
  email,
  ...props
}: PopupProps) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[9999]"
          onClose={() => {
            // setOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-6 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-full transform overflow-hidden rounded-lg bg-white p-4 text-left align-middle shadow-xl transition-all">
                  {/* Body */}
                  <div>
                    <iframe
                      className="w-full h-[calc(100vh_-_8rem)]"
                      src={
                        'https://blocksurvey.io/survey/t/7b7f2781-c80d-46b3-8b9c-9a781e93d2ef/r/o' +
                        '?orgId=' +
                        orgId +
                        '&email=' +
                        email
                      }
                    ></iframe>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
